/**
 * Animation
 */

import React from 'react';
import get from 'lodash/get';
import { graphql } from 'gatsby';
import { useInView } from 'react-hook-inview';
import { Image as Img, Video as V, Gif } from "@components";

const Animation = ({ alternative_text, image_placeholder, type, video_path, gif_path }) => {
  const placeholder = get(image_placeholder, 'localFile.childImageSharp.fluid', null);
  const [gifRef, gifIsVisible] = useInView({
    onEnter: (e) => e.target.play(),
    onLeave: (e) => e.target.pause(),
  });

  /**
   * Placeholder
   */
  const renderPlaceholder = () =>(
    <div className="image__image">
      <Img
        alt={alternative_text}
        fluid={placeholder}
        className="image__image"
      />
    </div>
  )

  /**
   * Video
   */
  const renderVideo = () => (
    <V path={video_path} />
  );

  /**
   * Gif
   */
  const renderGif = () => (
    <Gif
      alt={alternative_text}
      path={gif_path}
      className="image__image"
    />  
  );

  if(type === 'video') {
    return (
      <div className="page__block--video">
        <div className="video">
          <div className="video__video">
            <V autoPlay loop muted playsInline path={video_path} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="page__block--animation">
      <div className="image">
        {renderPlaceholder()}
        {renderGif()}
      </div>
    </div>
  )
}

export default Animation;

export const pageBuilderAnimationQuery = graphql`
  fragment PageBuilderAnimation on WordPressAcf_animation {
    ... on WordPressAcf_animation {
      type
      alternative_text
      image_placeholder {
        localFile {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      gif_path
      video_path
    }
  }
`